import { defineStore } from "pinia";

export const useLinksTableStore = defineStore("LinksTableStore", () => {
  if (process.server) return;

  const route = useRoute();
  const currentPath = computed(() => {
    const pathSegments = route.path.split("/");
    return pathSegments[pathSegments.length - 1];
  });

  const searchInput = ref();

  function setSearchInput(value) {
    searchInput.value = value;
  }

  return {
    searchInput,
    setSearchInput,
  };
});
